import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "path": "/developer/improving-javascript-performance-for-web",
  "date": "2021-11-21",
  "title": "Improving JavaScript Performance for Web",
  "author": "admin",
  "tags": ["development", "javascript", "performance"],
  "featuredImage": "feature.jpg",
  "excerpt": "JavaScript optimisation is becoming increasingly necessary for improving application performance. Let’s look at some of the challenges associated with JavaScript and how to optimise performance."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Why should we concern about JavaScript Performance?`}</h2>
    <p>{`JavaScript is a common language in mobile and web app development today. Nobody wants an app that crashes or a web page that is slow to load. The expectation for website load time has been around 2 seconds, or you will have to expect your visitors to leave.`}</p>
    <h2>{`What causes JavaScript to perform slow?`}</h2>
    <p>{`There are several areas that will have the biggest impact on both real and perceived performance in your site or app. We can look at a few separate types of JavaScript Performance issues:`}</p>
    <ol>
      <li parentName="ol">{`Slow parsing and compilation`}</li>
      <li parentName="ol">{`Render blocking`}</li>
      <li parentName="ol">{`Repaints and reflows`}</li>
      <li parentName="ol">{`Memory allocation`}</li>
      <li parentName="ol">{`Memory leaks`}</li>
    </ol>
    <h2><strong parentName="h2">{`Slow parsing and compilation`}</strong></h2>
    <p>{`Browsers need to parse and compile our code before they can run our code. Where possible this is done lazily to avoid spending time on code that will never run.`}</p>
    <h3><strong parentName="h3">{`Parsing`}</strong></h3>
    <p>{`The step where source code is turned into an intermediate representation to be consumed by a compiler (in V8, the bytecode compiler `}<a parentName="p" {...{
        "href": "https://v8.dev/blog/ignition-interpreter"
      }}>{`I`}</a>{`gnition).`}</p>
    <h3><strong parentName="h3">{`Critical path`}</strong></h3>
    <p>{`Parsing and compiling happen on the critical path of web page startup, and not all functions shipped to the browser are immediately needed during startup.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/2021-11-21-improving-javascript-performance-for-web/parsing.png",
        "alt": "Source: Google V8"
      }}></img></p>
    <p>{`Source: Google V8`}</p>
    <p>{`How soon a user can interact with your site can be delayed by the time in parsing/compiling code. The more JavaScript you send, the longer it will take to parse and compile it before your site is interactive.`}</p>
    <h3>{`Render Blocking`}</h3>
    <p>{`Render-blocking resources are portions of code in website files, usually CSS and JavaScript, that prevent a web page from loading quickly. These resources take a relatively long time for the browser to process, but may not be necessary for the immediate user experience. Render-blocking resources can be removed or delayed until the browser needs to process them.`}</p>
    <p>{`This includes:`}</p>
    <ul>
      <li parentName="ul">{`CSS stylesheets`}</li>
      <li parentName="ul">{`JavaScript files added in the `}<inlineCode parentName="li">{`<head>`}</inlineCode>{` section`}</li>
      <li parentName="ul">{`Fonts added from either CDN or a local server`}</li>
      <li parentName="ul">{`HTML imports (even though obsolete, you might still encounter them on legacy pages)`}</li>
    </ul>
    <p>{`In order to reduce the number and impact of render-blocking resources, let's look at five strategies:`}</p>
    <ol>
      <li parentName="ol">{`Don’t add CSS with the @import rule`}</li>
      <li parentName="ol">{`Use the media attribute for conditional CSS`}</li>
      <li parentName="ol">{`Use the defer and async attributes to eliminate render-blocking JavaScript`}</li>
      <li parentName="ol">{`Split, bundle, and minify CSS and JavaScript files`}</li>
    </ol>
    <h3>{`Repaints and reflows`}</h3>
    <p>{`A `}<strong parentName="p">{`repaint`}</strong>{` occurs when changes are made to an elements skin that changes visibly but does not affect its layout.`}</p>
    <p>{`Examples of this include `}<inlineCode parentName="p">{`outline`}</inlineCode>{`, `}<inlineCode parentName="p">{`visibility`}</inlineCode>{`, `}<inlineCode parentName="p">{`background`}</inlineCode>{`, or `}<inlineCode parentName="p">{`color`}</inlineCode>{`. According to Opera, repaint is expensive because the browser must verify the visibility of all other nodes in the DOM tree.`}</p>
    <p>{`A `}<strong parentName="p">{`reflow`}</strong>{` is even more critical to performance because it involves changes that affect the layout of a portion of the page (or the whole page).`}</p>
    <p>{`Examples that cause reflows include: adding or removing content, explicitly or implicitly changing `}<inlineCode parentName="p">{`width`}</inlineCode>{`, `}<inlineCode parentName="p">{`height`}</inlineCode>{`, `}<inlineCode parentName="p">{`font-family`}</inlineCode>{`, `}<inlineCode parentName="p">{`font-size`}</inlineCode>{` and more.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`for (let i = 1; i <= 100; i++ {
    const p = document.createElement('p');
    p.textContent = 'Newly created paragraph element';

    document.body.appendChild(p);
}
`}</code></pre>
    <p>{`The above code is very inefficient, causing 100 reflow processes for every new paragraph element appended.`}</p>
    <p>{`You can mitigate this computationally stressful process by using `}<inlineCode parentName="p">{`.createDocumentFragment()`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`const fragment = document.createDocumentFragment();

for (let i = 1; i <= 100; i++) {
  const p = document.createElement('p');
  p.textContent = 'Newly created paragraph element';

  fragment.appendChild(p);
}

document.body.appendChild(fragment);
`}</code></pre>
    <p>{`The above code will now instead only use the reflow process 1x for the creation of 100 new paragraph elements.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/2021-11-21-improving-javascript-performance-for-web/reflow.png",
        "alt": "Source: Google V8"
      }}></img></p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`Reflow`}</inlineCode>{`: compute the layout of each visible element (position and size).`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`Repaint`}</inlineCode>{`: renders the pixels to the screen.`}</li>
    </ul>
    <h2>{`Memory Allocation`}</h2>
    <p>{`Browsers have a finite amount of memory.`}</p>
    <p>{`The exact amount varies by browser and device, and on modern browsers and new devices, it can be quite high. But it’s not unlimited.`}</p>
    <p>{`Modern JavaScript applications tend to use `}<em parentName="p">{`a lot`}</em>{` of memory, and it shows. After a while, the apps start to get slow and laggy and sometimes will freeze entirely. This is particularly noticeable in single-page apps.`}</p>
    <p>{`The two biggest offenders:`}</p>
    <ol>
      <li parentName="ol">{`Lots of event listeners attached to individual elements`}</li>
      <li parentName="ol">{`Store huge amounts of data in memory`}</li>
    </ol>
    <p>{`Modern frameworks encourage the bad practice of attaching event listeners directly on elements.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<button onclick="doSomething()">Click Me</button>
`}</code></pre>
    <p>{`A better approach is to use event delegation, which greatly reduces the number of listeners in the browser memory.`}</p>
    <p><a parentName="p" {...{
        "href": "https://gomakethings.com/classes-vs.-prototypal-inheritance-in-vanilla-js/"
      }}>{`Using Constructors and prototypal inheritance`}</a>{` can also reduce the memory load for certain types of JavaScript libraries, particularly ones where the same methods and properties are shared by multiple items.`}</p>
    <p>{`But large apps also tend to store huge amounts of `}<em parentName="p">{`state`}</em>{` in memory.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`const appData = {
  // every single detail about the current state of the application...
};
`}</code></pre>
    <p>{`Across multiple views in a single-page app, the size of this in-memory data can grow rapidly so quickly. Storing state in the relevant component will help to control the size of the memory as those will be removed when the components unmount`}</p>
    <h2>{`Memory Leaks`}</h2>
    <p>{`The main cause of memory leaks in JavaScript is `}<em parentName="p">{`unwanted references`}</em>{`. JavaScript's garbage collector uses an algorithm known as `}<em parentName="p">{`mark-and-sweep`}</em>{`.`}</p>
    <ol>
      <li parentName="ol">{`The garbage collector builds a list of "roots". Roots usually are global variables to which a reference is kept in code. The window object is always present, so the garbage collector can consider it and all of its children to be always present (i.e. not garbage).`}</li>
      <li parentName="ol">{`All roots are inspected and marked as active (i.e. not garbage). All children are inspected recursively as well. Everything that can be reached from a root is not considered garbage.`}</li>
      <li parentName="ol">{`All pieces of memory not marked as active can now be considered garbage. The collector can now free that memory and return it to the OS.`}</li>
    </ol>
    <p>{`There are three types of common JavaScript leaks:`}</p>
    <ol>
      <li parentName="ol">{`Accidental global variables`}</li>
      <li parentName="ol">{`Forgotten timers or callbacks`}</li>
      <li parentName="ol">{`Out of DOM references`}</li>
    </ol>
    <h3><strong parentName="h3">{`Accidental global variables`}</strong></h3>
    <p>{`One of the ways in which JavaScript is permissive is in the way it handles undeclared variables: a reference to an undeclared variable creates a new variable inside the `}<em parentName="p">{`global`}</em>{` object. In the case of browsers, the global object is `}<inlineCode parentName="p">{`window`}</inlineCode>{`. In other words:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`function doSomething() {
  foo = 'this is a hidden global variable';
  // window.foo = "this is a hidden global variable";
}
`}</code></pre>
    <p>{`If `}<inlineCode parentName="p">{`foo`}</inlineCode>{` was supposed to hold a reference to a variable only inside the scope of the `}<inlineCode parentName="p">{`doSomething`}</inlineCode>{` function and you forget to use `}<inlineCode parentName="p">{`const`}</inlineCode>{` or `}<inlineCode parentName="p">{`let`}</inlineCode>{` to declare it, an unexpected global variable is created. In this example, leaking a simple string won't do much harm, but it could certainly be worse.`}</p>
    <h3><strong parentName="h3">{`Forgotten timers or callbacks`}</strong></h3>
    <p>{`The use of `}<inlineCode parentName="p">{`setInterval`}</inlineCode>{` is quite common in JavaScript. Other libraries provide observers and other facilities that take callbacks. Most of these libraries take care of making any references to the callback unreachable after their own instances become unreachable as well. In the case of setInterval, however, code like this is quite common:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`const data = getData();

setInterval(function() {
    const node = document.getElementById('Node');
    if(node) {
        // Do stuff with node and data
        node.innerHTML = JSON.stringify(data));
    }
}, 1000);
`}</code></pre>
    <p>{`This example illustrates what can happen with dangling timers: timers that make reference to nodes or data that is no longer required. The object represented by `}<inlineCode parentName="p">{`node`}</inlineCode>{` may be removed in the future, making the whole block inside the interval handler unnecessary. However, the handler, as the interval is still active, cannot be collected (the interval needs to be stopped for that to happen). If the interval handler cannot be collected, its dependencies cannot be collected either. That means that `}<inlineCode parentName="p">{`data`}</inlineCode>{`, which presumably stores sizable data, cannot be collected either.`}</p>
    <p>{`For the case of observers, it is important to make explicit calls to remove them once they are not needed anymore (or the associated object is about to be made unreachable).`}</p>
    <p>{`Most browserswill collect observer handlers once the observed object becomes unreachable, even if the listener is not explicitly removed. It remains good practice, however, to explicitly remove these observers before the object is disposed. For instance:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`const element = document.getElementById('button');

function onClick(event) {
  element.innerHtml = 'text';
}

element.addEventListener('click', onClick);
// ...
element.removeEventListener('click', onClick);

// important!
element.parentNode.removeChild(element);
`}</code></pre>
    <p>{`Now when an element goes out of scope, both element and onClick will be collected even in old browsers that don't
handle cycles well.`}</p>
    <h3><strong parentName="h3">{`Out of DOM references`}</strong></h3>
    <p>{`Sometimes it may be useful to store DOM nodes inside data structures. Suppose you want to rapidly update the contents of several rows in a table. It may make sense to store a reference to each DOM row in a dictionary or array. When this happens, two references to the same DOM element are kept: one in the DOM tree and the other in the dictionary. If at some point in the future you decide to remove these rows, you need to make both references unreachable.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`const elements = {
  button: document.getElementById('button'),
  image: document.getElementById('image'),
  text: document.getElementById('text'),
};

function doSomething() {
  image.src = 'http://some.url/image';
  button.click();
  console.log(text.innerHTML);
}

function removeButton() {
  // The button is a direct child of body.
  document.body.removeChild(document.getElementById('button'));

  // At this point, we still have a reference to #button in the global
  // elements dictionary. In other words, the button element is still in
  // memory and cannot be collected by the GC.
}
`}</code></pre>
    <p>{`An additional consideration for this has to do with references to inner or leaf nodes inside a DOM tree. Suppose you keep a reference to a specific cell of a table (a `}<inlineCode parentName="p">{`<td>`}</inlineCode>{` tag) in your JavaScript code. At some point in the future, you decide to remove the table from the DOM but keep the reference to that cell. Intuitively one may suppose the GC will collect everything but that cell. In practice, this won't happen: the cell is a child node of that table and children keep references to their parents. In other words, the reference to the table cell from JavaScript code causes the whole table to stay in memory. Consider this carefully when keeping references to DOM elements.`}</p>
    <h2>{`Summary`}</h2>
    <p>{`In this article we carefully looked at the issues that cause JavaScript performance and how to mitigate them for the following issues:`}</p>
    <ol>
      <li parentName="ol">{`Slow parsing and compilation`}</li>
      <li parentName="ol">{`Render blocking`}</li>
      <li parentName="ol">{`Repaints and reflows`}</li>
      <li parentName="ol">{`Memory allocation`}</li>
      <li parentName="ol">{`Memory leaks`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      